.home-page {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	background-color: white;
}

/* Section One */
.section-one {
	display:flex;
	justify-content: center;
	align-items: center;
	margin:50px;
	margin-top: 50px;
	margin-right: 100px;
	z-index: 1;
}
.motto {
	text-align: right;
    font-family: var(--header-font);
    line-height: 1.1;
}
.mission-statement {
	font-family: inherit;
	text-align: right;
	font-size: 1.2em;
	max-width: 300px;
}
.iphone-mockup-image {
	height:600px;
}
@media screen and (max-width: 800px) {
	.section-one {
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin:0px;
	}
	.motto {
		text-align: center;
		font-family: var(--header-font);
		line-height: 1.1;
	}
	.mission-statement {
		font-family: inherit;
		text-align: center;
		font-size: 1.2em;
	}
}

/* Section Two */
.section-two-bg-transition-div {
	margin-top: -250px;
    height: 300px;
    background: linear-gradient( white, var(--secondary-color-bg) );
}
.section-two-bg-transition-div.reverse {
	margin: 0px;
    height: 300px;
    background: linear-gradient( var(--secondary-color-bg), white );
}
.section-two {
	background-color: var(--secondary-color-bg);
	color: var(--secondary-color-fg);
	display: flex;
	flex-direction: column;
	align-items: center;
}
.section-two .header {
	white-space: nowrap;
	font-family: var(--header-font);
	text-align: center;
	margin-bottom: 10px;
}
.section-two .mission-statement {
	margin:20px;
	text-align: center;
	max-width: 500px;
}

/* Section Three */
.section-three {
	z-index: 1;
	margin:40px;
	margin-top: -150px;

	display: flex;
    flex-direction: column;
    align-items: center;
}


#logo {
	height: 50px;
}

#logo.small {
	height:50px;
}

#logo.medium {
	height:75px;
}

#logo.large {
	height:100px;
}

#logo.xlarge {
	height:150px;
}

#logo.xxlarge {
	height:200px;
}

#logo.shadow {
    box-shadow: 1px 0px 14px -1px #9e9e9e;
    border-radius: 25px;
}

#logo.fadein {
	opacity: 0;
	animation: fadein 2s ease-out forwards;
}

@keyframes fadein {
	0% {
		opacity: 0;
		transform: scale(.8);
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
.section {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section .header {
    text-align: right;
    font-family: var(--header-font);
    line-height: 1.1;
    margin:0px;
}

.section .paragraph {
    font-family: inherit;
	text-align: right;
	font-size: 1.2em;
	max-width: 300px;
}


@media screen and (max-width: 800px) {
	.section {
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.section .header {
		text-align: center;
		font-family: var(--header-font);
		line-height: 1.1;
        margin:0px;
	}
	.section .paragraph {
		font-family: inherit;
		text-align: center;
		font-size: 1.2em;
	}
}


.contact-page {
    min-height:100vh;
    padding-top: 100px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-page .header {
    text-align: center;
    font-family: var(--header-font);
    line-height: 1.1;
}


.contact-page .text-field {
    width: 300px;
    max-width: 80%;
    margin:10px;
}


.contact-page .first-last-name {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}


.contact-page .email {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin:15px;
}

.contact-page .email .text-field {
    max-width:80%;
    width:620px;
}

.contact-page .message {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.contact-page .message .text-field {
    max-width: 80%;
    width: 620px;
}


@media screen and (max-width: 800px) {
	.contact-page .first-last-name {
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-page .email .text-field {
        max-width:80%;
        width:300px;
    }

    .contact-page .message .text-field {
        max-width:80%;
        width:300px;
    }
    
}

.contact-method-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-method-div-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contact-anchor {
    text-decoration: none !important;
}

.social-media-icon {
    font-size:3em  !important;
    cursor:pointer !important;
    transition: 200ms !important;
}

.social-media-icon:hover {
    color: var(--secondary-color-bg);
}

.card {
	border-radius: 10px;
	box-shadow: 0px 2px 10px 0px gray;
	width:90%;
	max-width: 500px;
    background-color: white;
    padding:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card .card-header {
    font-family: var(--header-font);
    text-align: center;
    margin:0px;
    font-size: 1.5em;
}

.card .card-text {
    font-family: inherit;
    text-align: center;
}

.card .card-button {
    margin:10px;
    background-color: var(--secondary-color-bg);
}


.nav-footer {
    width:100%;
    display: flex;
    flex-direction: column;
    background-color: black;
    color:rgb(168, 168, 168);
    padding:20px;
    padding-bottom: 0px;
}

.nav-footer .copyright {
    text-align: center;
    color:gray;
    font-size: .8em;
    margin-top: 20px;
}

.nav-footer .ext-link-header {
    font-weight: bold;
    color:gray;
}

.nav-footer .ext-link {
    display: flex;
    align-items: center;
    font-size: .8em;
    color:lightgray;
    cursor:pointer;
}


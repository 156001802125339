@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Squada+One&display=swap');

html, body {
    margin: 0 !important;
}

:root {
	/* COLORS */
	/* --secondary-color-bg:gray; */
	--secondary-color-bg: rgb(0, 133, 9);
	--secondary-color-fg:white;
	--nav-bar-background: black;
	/* FONTS */
	--lato-font: 'Lato', sans-serif;
	--montserrat-font: 'Montserrat', sans-serif;
	--squada-font: var(--lato-font);
	--brand-name-font: var(--lato-font);
	--header-font: var(--lato-font);
	/* --squada-font: 'Squada One', cursive;
	--brand-name-font: 'Squada One', cursive;
	--header-font: 'Squada One', cursive; */
	font-family: var(--montserrat-font);
}

h1.header {
	font-size: 1.7em;
}

.brand-name-font {
	font-family: var(--brand-name-font) !important;
}
.row {
	display: flex;
	flex-direction: row;
}
.row.space-between {
	justify-content: space-between;
}
.row.space-evenly {
	justify-content: space-evenly;
}
.row.center {
	justify-content: center;
}
.col {
	display: flex;
	flex-direction: column;
}
.col.space-between {
	align-items: space-between;
}
.col.space-evenly {
	align-items: space-evenly;
}
.col.center {
	align-items: center;
}
.col.vert-space-between {
	justify-content: space-between;
}
.col.vert-space-evenly {
	justify-content: space-evenly;
}
.col.vert-center {
	justify-content: center;
}


html {
	background-color: black;
	height: 100%;
	overflow: auto;
}

body {
	height: 100%;
}


body {
	margin: 0;
	font-family: inherit !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
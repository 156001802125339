.about-page {
    padding-top: 67px;
    background-color: white;
}

.about-image {
    height:600px;
}



.about-page .section .col {
    margin-right: 50px;
}

@media screen and (max-width: 800px) {
	.about-page .section .col {
        margin-right: 0px;
    }
}

